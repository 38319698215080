<template></template>

<script>
export default {
  async created() {
    let isInLine = await this.$liff.isInClient();
    let getOs = await this.$liff.getOS();

    let isMobileDevice = window.matchMedia("(any-pointer: coarse)").matches;

    if (!isInLine && isMobileDevice && getOs == "web") {
      return (window.location.href = `line://app/${this.$liff_ID_Login}?redirect=/transaction?code=${this.$route.params.transactionCode}&redeemId=${this.$route.params.redeemId}`);
    } else {
      this.$router.replace({
        path: "/transaction",
        query: {
          code: this.$route.params.transactionCode,
          redeem_id: this.$route.params.redeemId
        }
      });
    }
  }
};
</script>

<style></style>
